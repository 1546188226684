<template>
    <component :is="`h${size}`" v-bind="attributes">
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        size: {
            type: [String, Number],
            required: false,
            default: () => 1,
        },
        color: {
            type: String,
            required: false,
            default: () => "default",
        },
    },
    data() {
        return {
            baseClasses: "text-gray-400",
            sizes: {
                1: "text-base font-bold uppercase px-8 py-4",
                2: "text-base font-bold px-8 py-4",
                3: "text-base italic px-8 py-4",
                4: "text-base aa font-bold uppercase px-8 py-2",
                5: "text-base aa font-bold px-8 py-2",
                6: "text-base aa italic px-8 py-2",
            },
            colors: {
                default: "",
                danger: "text-danger-500",
            },
        };
    },
    computed: {
        classes() {
            return `heading ${this.baseClasses} ${this.sizes[this.size]} ${this.colors[this.color]}`;
        },
        attributes() {
            return {
                class: this.classes,
                ...this.$attrs,
            };
        },
    },
};
</script>
